import React, { useState } from "react";
import { Box, Button, TextField, FormControl } from "@material-ui/core";
import createUser from "src/utils/createUser";
import CustomToastify, { toastMes } from "src/globalConf/notification";

function UserDataForm({
  handleComplete,
  userData,
  setUserData,
  setCreateUserRes,
  setUserID,
}) {
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    // Update the userData object using the setUserData function
    setUserData((prevUserData) => ({
      ...prevUserData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNextStep = async (e) => {
    e.preventDefault()
    const createUserResponse = await createUser(userData);
    if (createUserResponse.state) {
      await setCreateUserRes((pre) => createUserResponse);
      await setUserID(createUserResponse.data.id);
      handleComplete();
    } else {
      setSuccess((pre) => false);
      toastMes.success("Faild to create user");
    }
  };

  return (
    <Box sx={{width:"100%"}}>
      <FormControl sx={{width:"100%" }}
      // onSubmit={handleNextStep}
      >
        <TextField
          required
          className="w-100"
          label="First Name"
          margin="normal"
          name="firstName"
          onChange={handleInputChange}
          type="text"
          value={userData.firstName}
          variant="outlined"
        />

        <TextField
          required
          className="w-100"
          label="Last Name"
          margin="normal"
          name="lastName"
          onChange={handleInputChange}
          type="text"
          value={userData.lastName}
          variant="outlined"
        />

        <TextField
          required
          className="w-100"
          label="Mobile"
          margin="normal"
          name="mobile"
          onChange={handleInputChange}
          type="mobile"
          value={userData.mobile}
          variant="outlined"
        />

        <TextField
          required
          className="w-100"
          label="Email"
          margin="normal"
          name="email"
          onChange={handleInputChange}
          type="email"
          value={userData.email}
          variant="outlined"
        />

        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button color="primary" type="button" variant="contained" disabled>
            Back
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick= {handleNextStep}
          >
            Next
          </Button>
        </Box>
      </FormControl>
      <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
    </Box>
  );
}

export default UserDataForm;
