import { httpNonToken, endpoints } from "../globalConf/http-non-token";

export const geideaCreateOrder = async (data) => {
  let responseData = {};
  try {
    const res = await httpNonToken.post(`${endpoints.payment}/create`, data);
    const response = res.status === 201 ? true : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Operation Created Successfully";
      responseData.data = res.data;
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }
  return responseData;
};
