import { Typography } from "@material-ui/core";
import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import CustomToastify, { toastMes } from "src/globalConf/notification";
import checkCode from "src/utils/CheckPromoCode";

function CheckPromoCode({
  promoCode,
  setPromoCode,
  handleNext,
  handleComplete,
  handleBack,
  userID,
  setCheckPromoCode,
}) {
  const params = new URLSearchParams(window.location.search);
  const encodedData = params.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const myData = JSON.parse(decodedData);
  const register = params.get("register");
  const projectId = params.get("project");

  const [success, setSuccess] = useState(false);
  const [flag, setFlag] = useState(false);

  const checkPromo = async () => {
    setFlag((pre) => false);
    const data = {
      code: promoCode,
      user: userID,
      project: projectId,
      plan: myData?.id,
      value: parseFloat(myData?.value_annual),
      currency: myData?.currency,
    };

    let checkPromoCode = await checkCode(data);
    setCheckPromoCode((pre) => checkPromoCode);
    if (checkPromoCode.state) {
      setSuccess((pre) => true);
      toastMes.success("Valid Promo Code.");
      setFlag((pre) => false);
      handleComplete();
    } else {
      setFlag((pre) => true);
      setSuccess((pre) => false);
      toastMes.success("Invalid Promo Code.");
    }
  };
  return (
    <>
      <div>
        <TextField
          className="w-100"
          label="Promo Code"
          margin="normal"
          name="code"
          onChange={(e) => setPromoCode(e.target.value)}
          type="text"
          value={promoCode}
          variant="outlined"
        />
        {flag && (
          <Typography sx={{ color: "red" }}>Invalid Promo Code.</Typography>
        )}

        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={handleBack}
          >
            Back
          </Button>
          <Box sx={{ direction: "flex" }}>
            <a
              style={{
                color: "#0000ff",
                textDecoration: "underline",
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={handleNext}
            >
              <span>Skip</span>
            </a>
            <Button
              color="primary"
              type="button"
              variant="contained"
              onClick={checkPromo}
            >
              Check Promo Code
            </Button>
          </Box>
        </Box>
      </div>
      <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
    </>
  );
}

export default CheckPromoCode;
