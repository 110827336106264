/* eslint-disable */
import { Navigate } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentError from "./pages/PaymentError";
import PaymentResponse from "./pages/PaymentResponse";
import NotFound from "./pages/NotFound";
import Geidea from "./components/Geidea";
import Paytabs from "./components/Paytabs";
import Payment from "./pages/payment";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "project/", element: <Payment /> },
      { path: "", element: <Navigate to="/project/" /> },
      { path: "payment-success", element: <PaymentSuccess /> },
      { path: "payment-error", element: <PaymentError /> },
      { path: "payment-response", element: <PaymentResponse /> },
      { path: "geidea", element: <Geidea /> },
      { path: "paytabs", element: <Paytabs /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
