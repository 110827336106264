import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import UserDataForm from "./UserDataForm";
import CheckPromoCode from "./CheckPromoCode";
import ChoosePaymentGateWay from "./ChoosePaymentGateWay";
import { useState } from "react";

const steps = ["Personal Info", "Promo Code", "Check Out"];

export default function HorizontalNonLinearStepper() {
  const [payment, setPayment] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [userData, setUserData] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const [userID, setUserID] = useState();
  const [promoCode, setPromoCode] = useState("");
  const [checkPromoCodeRes, setCheckPromoCodeRes] = useState({
    state: false,
  });
  const [createUserRes, setCreateUserRes] = useState();

  if (userData) {
    localStorage.setItem("pyteqser", userData);
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "75%", margin: "auto" }} className="payment-modal m-auto">
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)} disabled>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* here i will add my components */}
            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography> */}
            {activeStep === 0 && (
              <Box sx={{ mt: 2, mb: 1, width: "100%" }}>
                <UserDataForm
                  handleComplete={handleComplete}
                  userData={userData}
                  setUserData={setUserData}
                  setCreateUserRes={setCreateUserRes}
                  setUserID={setUserID}
                />
              </Box>
            )}
            {activeStep === 1 && (
              <Box sx={{ mt: 2, mb: 1 }}>
                <CheckPromoCode
                  promoCode={promoCode}
                  setPromoCode={setPromoCode}
                  setCheckPromoCode={setCheckPromoCodeRes}
                  userData={userData}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleComplete={handleComplete}
                  userID={userID}
                />
              </Box>
            )}
            {activeStep === 2 && (
              <Box sx={{ mt: 2, mb: 1 }}>
                <ChoosePaymentGateWay
                  userData={userData}
                  payment={payment}
                  setPayment={setPayment}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleComplete={handleComplete}
                  createUserResponse={createUserRes}
                  checkPromoCodeRes={checkPromoCodeRes}
                  userId={userID}
                />
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {/* <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button> */}
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  // <Button onClick={handleComplete}>
                  //   {completedSteps() === totalSteps() - 1
                  //     ? "Finish"
                  //     : "Skip This Step"}
                  // </Button>
                  <></>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
