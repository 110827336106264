/* eslint-disable */
import React from "react";
import { httpNonToken, endpoints } from "../globalConf/http-non-token";

export const createOrder = async (data) => {
  let responseData = {};
  try {
    const res = await httpNonToken.post(`${endpoints.paypalOrder}`, data);
    const response = res.status == 201 ? true : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Operation Created Successfully";
      responseData.data = res.data;
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }
  return responseData;
};

export const captureOrder = async (id) => {
  let responseData = {};
  try {
    const res = await httpNonToken.post(
      `${endpoints.paypalCaptureOrder}?id=${id}`
    );

    const response = res.status == 200 ? true : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Order Capture Successfully";
      responseData.data = res.data;
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }

  return responseData;
};

export const getpaymentDeatils = async (transaction) => {
  let responseData = {};
  try {
    const res = await httpNonToken.get(
      `${endpoints.payment}?transaction=${transaction}`
    );

    const response = res.status == 200 ? true : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Details Successfully";
      responseData.data = res?.data?.results;
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }

  return responseData;
};
