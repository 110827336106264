import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { httpNonToken, endpoints } from "../globalConf/http-non-token";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { captureOrder, getpaymentDeatils } from "../utils/paypal";
import finishRegister from "../utils/finishRegister";
import {
  finishServiceRenew,
  finishServiceUpgrade,
} from "../utils/finishRenewAndUpgrade";
import { Box, Button } from "@material-ui/core";
import axios from "axios";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  var jsonPalObj = localStorage.getItem("Pal");
  var palObj = JSON.parse(jsonPalObj);

  const [searchParams] = useSearchParams();
  let finishRegisterResponse;

  const [finishRegisterRess, setfinishRegisterRess] = useState();
  useEffect(() => {
    (async () => {
      let payment_id = state ? state.orderId : "";
      let details;
      //TODO: make it run only for paypal
      if (searchParams.get("token") || payment_id) {
        let captureOrderResponse = await captureOrder(
          searchParams.get("token") || payment_id
        );

        details = await getpaymentDeatils(
          payment_id || searchParams.get("token")
        );
        if (!captureOrderResponse.state && !payment_id) {
          navigate("/payment-error");
        } else {
          finishRegisterResponse = await finishRegister({
            payment_id: details?.data[0]?.id,
            plan_id: palObj?.plan,
            duration: palObj?.duration,
            value: details?.data[0]?.total,
            value_setup: details?.data[0]?.value,
            plan_name: palObj?.plan_name,
          });

          setfinishRegisterRess((pre) => finishRegisterResponse);
          localStorage.removeItem("pt");
          localStorage.removeItem("pyteqser");
          localStorage.removeItem("Pal");
        }
      } else if (state) {
        finishRegisterResponse = await finishRegister({
          payment_id: details?.data[0]?.id,
          plan_id: palObj?.plan,
          duration: palObj?.duration,
          value: details?.data[0]?.total,
          value_setup: palObj?.value_setup,
          plan_name: palObj?.plan_name,
        });

        setfinishRegisterRess((pre) => finishRegisterResponse);

        localStorage.removeItem("geideaTransaction");
        localStorage.removeItem("pyteqser");
        return;
      }
    })();
  }, []);

  return (
    <Box className="h-100vh m-auto">
      <Helmet>
        <title>Teqneia | Payment-Success</title>
      </Helmet>
      <div className="page-body">
        <div className="image-failed">
          <img
            width="30%"
            className="m-auto d-block"
            src="/static/images/payment-gateway/success.svg"
            alt="payment successful"
          />
        </div>
        <h1 className="text-danger text-center mt-5">Successful Payment</h1>

        <Box>
          <h3 className="flex flex-row text-primary text-center mt-5">
            You can now check your Email box to complete your registration and
            get started!
          </h3>

          {/* <Button
            color="primary"
            className="m-auto px-5 mt-4"
            variant="contained"
            // onClick={navigateToHomePage}
          >
            <a href="">Continue To Gmail</a>
          </Button> */}
        </Box>

        {/* <p className="lead w-75 m-auto text-center mt-3">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p> */}
        <Box
          sx={{
            py: 2,
            paddingBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            className="m-auto px-5 mt-4"
            variant="outlined"
            // onClick={navigate("https://e-marts.online/")}
          >
            <a href="https://e-mart.app/">Return to home page</a>
          </Button>

          <Button
            color="primary"
            className="m-auto px-5 mt-4"
            variant="contained"
            disabled={finishRegisterRess?.data?.url ? false : true}
            // onClick={navigateToHomePage}
          >
            <a href={finishRegisterRess?.data?.url}>Continue Registration</a>
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default PaymentSuccess;
