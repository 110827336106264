/* eslint-disable */
import React from 'react';

const JWT = 'jobs-jwt';
const id = 'user-id';

export const setToken = (token) => {
  sessionStorage.setItem(JWT, token);
};

export const setUserData = (userData) => {
  sessionStorage.setItem('user-data', JSON.stringify(userData));
};
export const getUserData = JSON.parse(sessionStorage.getItem('user-data'));
export const removeUserData = () => sessionStorage.removeItem('user-data');
export const getToken = () => sessionStorage.getItem(JWT);
export const removeToken = () => sessionStorage.removeItem(JWT);
export const isLoggedIn = () => !!getToken();
