/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import finishRegister from "../utils/finishRegister";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { httpNonToken, endpoints } from "../globalConf/http-non-token";

import { Box, Button } from "@material-ui/core";

const PaymentResponse = () => {
  let navigate = useNavigate();
  // let { order, txn_response_code, data, success, error_occured } = useParams();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        const paymentResponse = await httpNonToken.get(
          `${endpoints.payment}?transaction=${searchParams.get("order")}`
        );
        if (paymentResponse.data.results[0].status.name === "SUCCESS") {
          navigate("/payment-success", {
            state: { orderId: searchParams.get("order") },
          });
        } else {
          navigate("/payment-error");
        }
      } catch ({ response }) {
        navigate("/payment-error");
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Teqneia | Payment-Response</title>
      </Helmet>
      {/* <div className="image-failed w-100 m-auto">
        <img
          width="100%"
          height="550px"
          className="m-auto d-block"
          src="/static/images/payment-gateway/payment-photo.jpg"
          alt="payment response"
        />
      </div> */}
    </>
  );
};

export default PaymentResponse;
