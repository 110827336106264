/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/Navbar.css';

const Navbar = () => {
  return (
    <div className="nav">
      <input type="checkbox" id="nav-check" />
      <div className="nav-header">
        <div className="nav-title">
          <a href="https://teqneia.com/">
            <img className="w-50" src="/static/logo.png" alt="teqneia logo" />
          </a>
        </div>
      </div>
      {/* <div className="nav-btn">
        <label htmlFor="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div> */}

      {/* <div className="nav-links">
        <a href="https://teqneia.com/">About</a>
        <a href="https://teqneia.com/">Products</a>
        <a href="https://teqneia.com/">Services</a>
        <a href="https://teqneia.com/">Clients</a>
        <a href="https://teqneia.com/">Core Values</a>
        <a href="https://teqneia.com/">Contact Us</a>
        <a href="https://teqneia.com/">Join Us</a>
      </div> */}
    </div>
  );
};

export default Navbar;
