/* eslint-disable */
import React from 'react';
import { httpNonToken, endpoints } from '../globalConf/http-non-token';
import axios from 'axios';

const checkCode = async (data) => {
  let responseData = {};
  try {
    const res = await axios.post(`https://py.teqneia.com/api/v1/promo/check`, data);
    const response = res.status == 200 ? true : false;
    if (response) {
      responseData.state = true;
      responseData.message = 'Valid Promo Code';
      responseData.data = res.data;
    } else {
      responseData.state = false;
      responseData.message = 'Invalid Promo Code..';
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }
  return responseData;
};

export default checkCode;
