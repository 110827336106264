/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import CustomToastify, { toastMes } from "../globalConf/notification";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import createUser from "../utils/createUser";
import { createOrder } from "../utils/paypal";
import { paymobCreateOrder } from "../utils/paymob";
import axios from "axios";
import { endpoints, httpNonToken } from "src/globalConf/http-non-token";
import checkCode from "src/utils/CheckPromoCode";
import HorizontalNonLinearStepper from "./Payment/index";

const steps = ["Personal Info", "Checkout"];

const Payment = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [payment, setPayment] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [getway, setGetway] = useState();
  // const [paymentId, setPaymentId] = useState();

  //Plan Details
  const params = new URLSearchParams(window.location.search);
  const encodedData = params.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const myData = JSON.parse(decodedData);
  const register = params.get("register");
  const projectId = params.get("project");

  //user Form
  const [userData, setUserData] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  if (userData) {
    localStorage.setItem("pyteqser", userData);
  }

  useEffect(async () => {
    const res = await httpNonToken.get(`${endpoints.getway}/?is_active=1`);
    setGetway(res.data.results);
  }, []);

  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };

  // const handleNext = () => {
  //   let newSkipped = skipped;

  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(activeStep);
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handlePaymentChange = (event) => {
  //   setPayment(event.target.value);
  // };

  // const handleFormSubmit = async () => {
  //   if (!payment) {
  //     setSuccess(false);
  //     toastMes.error("Please Select Payment Method First ");
  //     return;
  //   }
  //   const createUserResponse = await createUser(userData);

  //   const data = {
  //     code: promoCode,
  //     user: createUserResponse.data.id,
  //     project: projectId,
  //     plan: myData?.id,
  //     value: parseFloat(myData?.value_annual),
  //     currency: myData?.currency,
  //   };
  //   let CheckPromoCode = {
  //     state: true,
  //   };
  //   if (promoCode !== "") {
  //     CheckPromoCode = await checkCode(data);
  //     if (CheckPromoCode.state) {
  //       setSuccess((pre) => true);
  //       toastMes.success("Valid Promo Code");
  //       navigate("/geidea", {
  //         state: {
  //           value: CheckPromoCode?.data?.total,
  //           currency: myData?.currency,
  //           userInfo: createUserResponse.data.id,
  //           plan: myData?.id,
  //           close: handleClose(),
  //           userDataFromService: userData,
  //           register: register,
  //           duration: 12,
  //           value_setup: 0,
  //           // value_setup: myData?.value_setup === null ? 0 : myData?.value_setup,
  //           plan_name: myData?.name,
  //           project: projectId,
  //         },
  //       });
  //       setSuccess(false);
  //     } else {
  //       toastMes.error("Invaled Promo Code");
  //     }
  //   }

  //   if (createUserResponse.state && payment == "paypal") {
  //     const createOrderPaypalRes = await createOrder({
  //       plan: myData.id,
  //       userInfo: createUserResponse.data.id,
  //       register: true,
  //       // register: Object.keys(userDataFromService).length > 0 ? false : true,
  //       value: parseFloat(myData?.value_annual),
  //     });

  //     if (createOrderPaypalRes.state) {
  //       const token = getParameterByName(
  //         "token",
  //         createOrderPaypalRes.data.url
  //       );

  //       let tokenEncode = btoa(token);
  //       let nTms = Date.now();
  //       let tms = Date.now("1994-04-90");
  //       let tokenHashed = `${nTms}.${tokenEncode}.${tms}45872695${tokenEncode}t451eq961`;
  //       localStorage.setItem("pt", tokenHashed);
  //       window.open(
  //         createOrderPaypalRes.data.url,
  //         "_target",
  //         "noopener noreferrer"
  //       );
  //       handleClose();
  //     } else {
  //       setSuccess(false);
  //       toastMes.error(createOrderPaypalRes.message);
  //     }
  //   } else if (createUserResponse.state && payment == "paymob") {
  //     const createOrderPaymobRes = await paymobCreateOrder({
  //       plan: myData.id,
  //       userInfo: createUserResponse.data.id,
  //       // register: Object.keys(userDataFromService).length > 0 ? false : true,
  //       register: true,
  //       value: parseFloat(myData?.value_annual),
  //     });
  //     if (createOrderPaymobRes.state) {
  //       window.open(
  //         createOrderPaymobRes.data.url,
  //         "_target",
  //         "noopener noreferrer"
  //       );
  //       handleClose();
  //     } else {
  //       toastMes.error(createOrderPaymobRes.message);
  //     }
  //   } else if (
  //     createUserResponse.state &&
  //     payment == "geidea" &&
  //     CheckPromoCode.state
  //   ) {
  //     navigate("/geidea", {
  //       state: {
  //         value: parseFloat(myData?.value_annual),
  //         currency: myData?.currency,
  //         userInfo: createUserResponse.data.id,
  //         plan: myData?.id,
  //         close: handleClose(),
  //         userDataFromService: userData,
  //         register: register,
  //         duration: 12,
  //         value_setup: 0,
  //         // value_setup: myData?.value_setup === null ? 0 : myData?.value_setup,
  //         plan_name: myData?.name,
  //         project: projectId,
  //       },
  //     });
  //   } else {
  //     setSuccess(false);
  //     toastMes.error(createUserResponse.message);
  //   }
  // };

  // function getParameterByName(name, url = window.location.href) {
  //   name = name.replace(/[\[\]]/g, "\\$&");
  //   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
  //     results = regex.exec(url);
  //   if (!results) return null;
  //   if (!results[2]) return "";
  //   return decodeURIComponent(results[2].replace(/\+/g, " "));
  // }

  // const handleClose = () => {
  //   if (userData) return;
  //   setOpen(false);
  // };

  return (
    <>
      <Helmet>
        <title>Teqneia | Plans</title>
      </Helmet>

      <HorizontalNonLinearStepper />

      {/* <Box sx={{ width: "50%" }} className="payment-modal m-auto">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          </React.Fragment>
        ) : (
          // form
          <>
            <Box sx={{ mt: 2, mb: 1 }}>
              {activeStep == 0 ? (
                <Formik
                  initialValues={{
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    mobile: userData.mobile,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                    firstName: Yup.string()
                      .max(50)
                      .required("First Name is required"),
                    lastName: Yup.string()
                      .max(50)
                      .required("Last Name is required"),
                    mobile: Yup.string()
                      .max(50)
                      .required("Phone Number is required"),
                  })}
                  onSubmit={async (values) => {
                    setUserData(values);
                    handleNext();
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        className="w-100"
                        helperText={touched.firstName && errors.firstName}
                        label="First Name"
                        margin="normal"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.firstName}
                        variant="outlined"
                        // disabled={Object.keys(userDataFromService).length > 0}
                      />
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        className="w-100"
                        helperText={touched.lastName && errors.lastName}
                        label="Last Name"
                        margin="normal"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.lastName}
                        variant="outlined"
                        // disabled={Object.keys(userDataFromService).length > 0}
                      />
                      <TextField
                        error={Boolean(touched.mobile && errors.mobile)}
                        className="w-100"
                        helperText={touched.mobile && errors.mobile}
                        label="Mobile"
                        margin="normal"
                        name="mobile"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.mobile}
                        variant="outlined"
                        // disabled={Object.keys(userDataFromService).length > 0}
                      />

                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        className="w-100"
                        helperText={touched.email && errors.email}
                        label="Email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                        // disabled={Object.keys(userDataFromService).length > 0}
                      />

                      <TextField
                        // error={Boolean(touched.lastName && errors.lastName)}
                        className="w-100"
                        // helperText={touched.code}
                        label="Promo Code"
                        margin="normal"
                        name="code"
                        onBlur={handleBlur}
                        onChange={(e) => setPromoCode(e.target.value)}
                        type="text"
                        value={promoCode}
                        variant="outlined"
                        // disabled={Object.keys(userDataFromService).length > 0}
                      />

                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          color="primary"
                          // disabled={
                          //   activeStep === 0 ||
                          //   Object.keys(userDataFromService).length > 0
                          // }
                          onClick={handleBack}
                          variant="contained"
                          disabled={activeStep === 0}
                        >
                          Back
                        </Button>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          Next
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                <FormControl component="fieldset" sx={{ display: "block" }}>
                  {getway.length >= 1 ? (
                    <RadioGroup
                      name="payment"
                      value={payment}
                      onChange={handlePaymentChange}
                    >
                      {getway.map((item) => (
                        <div className="row" style={{ height: "128px" }}>
                          <div className="col-1">
                            <FormControlLabel
                              value={item.name}
                              control={<Radio />}
                              label=""
                            />
                          </div>

                          <div className="col-11">
                            <div className="row">
                              <div className="col-12">
                                <img
                                  src={`/static/images/payment-gateway/${item.name}.png`}
                                  className="h-50"
                                />
                              </div>
                              <div className="col-12">
                                <p
                                  className="credit-text"
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  You will be redirect to {item.name} website to
                                  complete your purchase securely.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </RadioGroup>
                  ) : (
                    ""
                  )}

                  <Box
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="primary"
                      // disabled={
                      //   activeStep === 0 ||
                      //   Object.keys(userDataFromService).length > 0
                      // }
                      onClick={handleBack}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFormSubmit}
                    >
                      Next
                    </Button>
                  </Box>
                </FormControl>
              )}
            </Box>
          </>
        )}
        <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
      </Box> */}
    </>
  );
};

export default Payment;
