import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { endpoints, httpNonToken } from "src/globalConf/http-non-token";

const Paytabs = () => {
  const { state } = useLocation();
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const payload = {
      cart_id: state?.cartId.toString(),
      cart_description: "Thank you for making this order", //TODO:to be updated by the database later
      cart_currency: state?.currency,
      cart_amount: state?.total,
      callback: endpoints.response,
      return: endpoints.redirect,
      customer_details: {
        name:
          state.userDataFromService?.firstName +
          " " +
          state.userDataFromService?.lastName,
        email: state.userDataFromService.email,
        phone: state.userDataFromService.mobile,
      },
    };
    httpNonToken
      .post(endpoints.paytabs, payload, config)
      .then((response) => {
        window.location.href = response.data.redirect_url;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return <div />;
};

export default Paytabs;
