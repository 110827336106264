/* eslint-disable */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@material-ui/core";

const PaymentError = () => {
  let navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;

    (async () => {
      let token = localStorage.getItem("pt");
      if (token) {
        localStorage.removeItem("pt");
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const navigateToHomePage = () => {
    window.open("https://www.teqneia.com/", "_self");
  };

  return (
    <>
      <Helmet>
        <title>Teqneia | Payment-Error</title>
      </Helmet>
      <div className="page-body">
        <div className="image-failed">
          <img
            width="30%"
            className="m-auto d-block"
            src="/static/images/payment-gateway/failed.svg"
            alt="payment failed"
          />
        </div>
        <h1 className="text-danger text-center mt-5">Failed Payment</h1>
        {/* <p className="lead w-75 m-auto text-center mt-3">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
  </p> */}
        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            className="m-auto px-5 mt-4"
            variant="contained"
            onClick={navigateToHomePage}
          >
            Continue
          </Button>
        </Box>
      </div>
    </>
  );
};

export default PaymentError;
