import React, { useEffect, useState } from "react";
import { endpoints, httpNonToken } from "src/globalConf/http-non-token";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomToastify, { toastMes } from "src/globalConf/notification";
import { useNavigate } from "react-router";
import { paymobCreateOrder } from "src/utils/paymob";
import { createOrder } from "src/utils/paypal";

function ChoosePaymentGateWay({
  payment,
  setPayment,
  createUserResponse,
  userData,
  checkPromoCodeRes,
  handleNext,
  handleBack,
  handleComplete,
  userId,
}) {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const encodedData = params.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const myData = JSON.parse(decodedData);
  const register = params.get("register");
  const projectId = params.get("project");
  const [UserPayment, setUserPayment] = useState();
  const [getway, setGetway] = useState();
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  // Your object
  var palObj = {
    promoCodeID: checkPromoCodeRes?.data?.promo?.id,
    usePromoCode: checkPromoCodeRes?.data?.promo?.code,
    promoCodeResponse: checkPromoCodeRes,
    currency: myData?.currency,
    userInfo: createUserResponse.data.id,
    plan: myData?.id,
    userDataFromService: userData,
    register: register,
    duration: 12,
    value_setup: 0,
    plan_name: myData?.name,
    project: projectId,
  };

  // Convert the object to a JSON string
  var jsonPalObj = JSON.stringify(palObj);

  // Store the JSON string in local storage
  localStorage.setItem("Pal", jsonPalObj);

  //get active payment methods
  useEffect(async () => {
    const res = await httpNonToken.get(
      `${endpoints.getway}/?is_active=1&currency=${myData?.currency}`
    );
    setGetway((pre) => res.data.results);

    if (res?.data?.results?.length === 1) {
      const paymentGatewayRes = res?.data?.results[0]?.name;
      setUserPayment((pre) => paymentGatewayRes);
      await setPayment((pre) => res?.data?.results[0]?.name);
      await handleFormSubmit(paymentGatewayRes);
    }
  }, []);

  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
  };
  const handleFormSubmit = async (paymentGatewayRes) => {
    //did the user choose a payment gatway?
    if (paymentGatewayRes === "" && !payment) {
      setSuccess(false);
      setFlag(true);
      toastMes.error("Please Select Payment Method First ");
      return;
    }
    //did the user choose paypal without promo code
    if (
      createUserResponse.state &&
      (payment === "paypal" || paymentGatewayRes === "paypal") &&
      !checkPromoCodeRes.state
    ) {
      const createOrderPaypalRes = await createOrder({
        project: projectId,
        // plan: myData.id,
        userInfo: createUserResponse.data.id,
        register: true,
        total: parseFloat(myData?.value_annual),
        value: parseFloat(myData?.value_annual),
        // currency: myData?.currency,
      });

      if (createOrderPaypalRes.state) {
        const token = getParameterByName(
          "token",
          createOrderPaypalRes.data.url
        );

        let tokenEncode = btoa(token);
        let nTms = Date.now();
        let tms = Date.now("1994-04-90");
        let tokenHashed = `${nTms}.${tokenEncode}.${tms}45872695${tokenEncode}t451eq961`;
        localStorage.setItem("pt", tokenHashed);
        window.open(
          createOrderPaypalRes.data.url,
          "_target",
          "noopener noreferrer"
        );
        handleClose();
      } else {
        setSuccess(false);
        toastMes.error(createOrderPaypalRes.message);
      }
    }
    //did the user choose paypal with promocode
    else if (
      checkPromoCodeRes.state &&
      (payment === "paypal" || paymentGatewayRes === "paypal")
    ) {
      const createOrderPaypalRes = await createOrder({
        project: projectId,
        // plan: myData.id,
        userInfo: createUserResponse.data.id,
        register: true,
        value: parseFloat(myData?.value_annual),
        total: checkPromoCodeRes?.data?.total,
        // currency: myData?.currency,
      });

      if (createOrderPaypalRes.state) {
        const token = getParameterByName(
          "token",
          createOrderPaypalRes.data.url
        );

        let tokenEncode = btoa(token);
        let nTms = Date.now();
        let tms = Date.now("1994-04-90");
        let tokenHashed = `${nTms}.${tokenEncode}.${tms}45872695${tokenEncode}t451eq961`;
        localStorage.setItem("pt", tokenHashed);
        window.open(
          createOrderPaypalRes.data.url,
          "_target",
          "noopener noreferrer"
        );
        handleClose();
      } else {
        setSuccess(false);
        toastMes.error(createOrderPaypalRes.message);
      }
    }
    //did the user chose paymob
    else if (createUserResponse.state && payment === "paymob-hold") {
      const createOrderPaymobRes = await paymobCreateOrder({
        plan: myData.id,
        userInfo: createUserResponse.data.id,
        // register: Object.keys(userDataFromService).length > 0 ? false : true,
        register: true,
        value: parseFloat(myData?.value_annual),
      });
      if (createOrderPaymobRes.state) {
        window.open(
          createOrderPaymobRes.data.url,
          "_target",
          "noopener noreferrer"
        );
        handleClose();
      } else {
        toastMes.error(createOrderPaymobRes.message);
      }
    }
    ///////////////////////////////////////////////////
    else if (createUserResponse.state && payment === "paymob") {
      //   {
      //     "amount": 3000,
      //     "items": [
      //     {
      //       "name": "Item name 1",
      //       "amount": 3000,
      //       "description": "Watch",
      //       "quantity": 1
      //     }
      //     ],
      //     "billing_data": {
      //     "first_name": "Ammar",
      //     "last_name": "Sadek",
      //     "phone_number": "+96824480228",
      //     "email": "AmmarSadek@gmail.com"
      //     }
      // }
      const data = {
        amount: checkPromoCodeRes?.data?.total
          ? checkPromoCodeRes?.data?.total
          : myData.value_annual,
        items: [
          {
            name: "E-Mart Subscription",
            amount: checkPromoCodeRes?.data?.total
              ? checkPromoCodeRes?.data?.total
              : myData.value_annual,
            description: myData.name + " plan (12 months)",
            quantity: 1,
          },
        ],
        billing_data: {
          first_name: userData.firstName,
          last_name: userData.lastName,
          phone_number: userData.mobile,
          email: userData.email,
        },
        extras: {
          ee: createUserResponse.data.id,
        },
      };

      console.log(userData);
      const createOrderPaymobRes = await httpNonToken.post(
        `payment/paymob/payment-intent/`,
        data
      );

      window.open(createOrderPaymobRes.data.checkout_url);
      // const createOrderPaymobRes = await paymobCreateOrder({
      //   plan: myData.id,
      //   userInfo: createUserResponse.data.id,
      //   // register: Object.keys(userDataFromService).length > 0 ? false : true,
      //   register: true,
      //   value: parseFloat(myData?.value_annual),
      // });
      // if (createOrderPaymobRes.state) {
      //   window.open(
      //     createOrderPaymobRes.data.url,
      //     "_target",
      //     "noopener noreferrer"
      //   );
      //   handleClose();
      // } else {
      //   toastMes.error(createOrderPaymobRes.message);
      // }
    }
    ///////////////////////////////////////////////////
    //did the user choose geidia without promocode
    else if (
      createUserResponse.state &&
      (payment === "geidea" || paymentGatewayRes === "geidea") &&
      !checkPromoCodeRes.state
    ) {
      navigate("/geidea", {
        state: {
          total: parseFloat(myData?.value_annual),
          value: parseFloat(myData?.value_annual),
          promoCodeResponse: checkPromoCodeRes,
          currency: myData?.currency,
          userInfo: createUserResponse.data.id,
          plan: myData?.id,
          close: handleClose(),
          userDataFromService: userData,
          register: register,
          duration: 12,
          value_setup: 0,
          // value_setup: myData?.value_setup === null ? 0 : myData?.value_setup,
          plan_name: myData?.name,
          project: projectId,
        },
      });
    }
    //did the user choose geidia with promocode
    else if (
      checkPromoCodeRes.state &&
      (payment === "geidea" || paymentGatewayRes === "geidea")
    ) {
      setSuccess((pre) => true);
      toastMes.success("Valid Promo Code");
      navigate("/geidea", {
        state: {
          value: parseFloat(myData?.value_annual),
          total: checkPromoCodeRes?.data?.total,
          promoCodeID: checkPromoCodeRes?.data?.promo?.id,
          usePromoCode: checkPromoCodeRes?.data?.promo?.code,
          promoCodeResponse: checkPromoCodeRes,
          currency: myData?.currency,
          userInfo: createUserResponse.data.id,
          plan: myData?.id,
          close: handleClose(),
          userDataFromService: userData,
          register: register,
          duration: 12,
          value_setup: 0,
          plan_name: myData?.name,
          project: projectId,
        },
      });
      setSuccess(false);
    }
    //did the user choose paytabs without promo code
    else if (
      createUserResponse.state &&
      (payment === "paytabs" || paymentGatewayRes === "paytabs") &&
      !checkPromoCodeRes.state
    ) {
      navigate("/paytabs", {
        state: {
          total: parseFloat(myData?.value_annual),
          value: parseFloat(myData?.value_annual),
          promoCodeResponse: checkPromoCodeRes,
          currency: myData?.currency,
          userInfo: createUserResponse.data.id,
          plan: myData?.id,
          close: handleClose(),
          userDataFromService: userData,
          register: register,
          duration: 12,
          value_setup: myData?.value_setup === null ? 0 : myData?.value_setup,
          plan_name: myData?.name,
          project: projectId,
          cartId: userId,
        },
      });
    }
    //did the user choosed paytabs with promo code
    else if (
      createUserResponse.state &&
      (payment === "paytabs" || paymentGatewayRes === "paytabs") &&
      checkPromoCodeRes.state
    ) {
      navigate("/paytabs", {
        state: {
          total: checkPromoCodeRes?.data?.total,
          value: parseFloat(myData?.value_annual),
          promoCodeResponse: checkPromoCodeRes,
          currency: myData?.currency,
          userInfo: createUserResponse.data.id,
          plan: myData?.id,
          close: handleClose(),
          userDataFromService: userData,
          register: register,
          duration: 12,
          value_setup: myData?.value_setup === null ? 0 : myData?.value_setup,
          plan_name: myData?.name,
          project: projectId,
          cartId: userId,
        },
      });
    } else {
      setSuccess(false);
      toastMes.error(createUserResponse.message);
    }
  };

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const handleClose = () => {
    if (userData) return;
    setOpen(false);
  };

  return (
    <div>
      <FormControl component="fieldset" sx={{ display: "block" }}>
        {UserPayment === "paypal" && (
          <h3
            style={{
              color: "red",
              margin: "auto",
              textAlign: "center",
              marginTop: 5,
              marginBottom: 11,
            }}
          >
            {" "}
            Please Wait While We Redirect You To Payment Page.
          </h3>
        )}
        {getway?.length >= 1 ? (
          <RadioGroup
            name="payment"
            value={payment}
            onChange={handlePaymentChange}
          >
            {getway.map((item, index) => (
              <div key={index} className="row" style={{ height: "128px" }}>
                <div className="col-1">
                  <FormControlLabel
                    value={item.name}
                    control={<Radio />}
                    // label={item.name}
                  />
                </div>

                <div className="col-11">
                  <div className="row">
                    <div className="col-12">
                      <img
                        alt=""
                        src={`/static/images/payment-gateway/${item.name}.png`}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="col-12">
                      <p
                        className="credit-text"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        You will be redirect to {item.name} website to complete
                        your purchase securely.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </RadioGroup>
        ) : (
          <></>
        )}

        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button color="primary" onClick={handleBack} variant="contained">
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={UserPayment === "paypal"}
            onClick={handleFormSubmit}
          >
            Next
          </Button>
        </Box>
      </FormControl>
      {flag && (
        <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
      )}{" "}
    </div>
  );
}

export default ChoosePaymentGateWay;
