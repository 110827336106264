/* eslint-disable */
import React from "react";
import { httpNonToken, endpoints } from "../globalConf/http-non-token";

const finishServiceRenew = async (data) => {
  let responseData = {};
  try {
    const res = await httpNonToken.post(
      `${endpoints.finishServiceRenew}`,
      data
    );
    const response =
      res.status == 201 || res.status == 200 || res.status == 204
        ? true
        : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Renew Finished Successfully";
      responseData.data = res.data;
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }
  return responseData;
};

const finishServiceUpgrade = async (data) => {
  let responseData = {};
  try {
    const res = await httpNonToken.post(
      `${endpoints.finishServiceUpgrade}`,
      data
    );
    const response =
      res.status == 201 || res.status == 200 || res.status == 204
        ? true
        : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Upgrade Finished Successfully";
      responseData.data = res.data;
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }
  return responseData;
};

export { finishServiceRenew, finishServiceUpgrade };
