import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { geideaCreateOrder } from "./../utils/Geidea";
import checkPaymentStatus from "src/utils/CheckPaymentStatus";
import { endpoints, httpNonToken } from "src/globalConf/http-non-token";

const Geidea = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [gatewayData, setGatewayData] = useState("");

  useEffect(() => {
    const fetchGatewayData = async () => {
      try {
        const res = await httpNonToken.get(
          `${endpoints.gatewayConfig}?production=True`
        );
        setGatewayData(res);
      } catch (error) {
        // Handle error if necessary
        console.error("Error fetching gateway data:", error);
      }
    };

    fetchGatewayData();
  }, []);

  const checkResStatus = async (GStatus) => {
    const checkStatus = await checkPaymentStatus();
    const successStatus = checkStatus.find(
      (status) => status.name.toLowerCase() === GStatus.toLowerCase()
    );
    return successStatus?.id;
  };

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  useEffect(() => {
    if (gatewayData) {
      pay();
    }
  }, [gatewayData]);

  const pay = async () => {
    const onSuccess = async (res) => {
      const checkGeideaStatusRes = await checkResStatus(res.responseMessage);
      const data = {
        transaction: res?.orderId,
        value: state?.value,
        total: state?.total,
        promo: state.promoCodeResponse.state
          ? state?.promoCodeResponse?.data?.promo?.code
          : null,
        currency: state.currency,
        getway: gatewayData?.data?.results[0]?.getway,
        status: checkGeideaStatusRes,
        userInfo: state.userInfo,
        plan: state.plan,
        register: true,
        project: state?.project,
      };

      const createOrderGeideaRes = await geideaCreateOrder(data);
      if (createOrderGeideaRes.state) {
        const token = getParameterByName(
          "token",
          createOrderGeideaRes.data.transaction
        );

        localStorage.setItem("geideaTransaction", res.orderId);
      }

      if (res.responseMessage === "Success") {
        navigate("/payment-success", {
          state: {
            transaction: createOrderGeideaRes?.data?.transaction,
            payment_id: createOrderGeideaRes?.data?.id,
            planData: state,
          },
        });
      } else {
        navigate("/payment-error");
      }
    };

    const onError = (error) => {
      // console.log({ error });
      // console.log(error, "onError");
      // navigate("/payment-error");
    };

    const onCancel = (res) => {
      // console.log({ res });
      ///////////////////////////////
      // navigate("https://e-marts.online/plans");
      window.location.href = "https://e-mart.app/plans";
    };

    const payment = new window.GeideaApi(
      gatewayData?.data?.results[0]?.value,
      onSuccess,
      onError,
      onCancel
    );
    payment.configurePayment({
      amount: state?.total,
      currency: state?.currency,
      callbackUrl: "https://payment.teqneia.com/payment-response",
    });
    payment.startPayment();
  };

  return <div />;
};

export default Geidea;
