/* eslint-disable */
import React from "react";
import { httpNonToken, endpoints } from "../globalConf/http-non-token";
import { toast } from "react-toastify";

const finishRegister = async (data) => {
  let responseData = {};
  try {
    const res = await httpNonToken.post(`${endpoints.finishRegister}`, data);
    const response =
      res.status == 201 || res.status == 200 || res.status == 204
        ? true
        : false;
    if (response) {
      responseData.state = true;
      responseData.message = "Register Finished Successfully";
      responseData.data = res.data;
      toast.success(responseData.data.message);
    } else {
      responseData.state = false;
      responseData.message = "Something went wrong ..";
    }
  } catch ({ response }) {
    responseData.state = false;
    responseData.message = response.data.message;
  }
  return responseData;
};

export default finishRegister;
