/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastMes = {
  success(msg) {
    return toast.success(msg);
  },
  info(msg) {
    return toast.info(msg);
  },
  warning(msg) {
    return toast.warning(msg);
  },
  error(msg) {
    return toast.error(msg);
  }
};

const CustomToastify = ({ bgColor, duration }) => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={duration}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: bgColor,
          color: 'white',
          width: '500px',
          left: '-180px'
        }}
      />
    </>
  );
};

export default CustomToastify;
